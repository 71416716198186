import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EEnvironment } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import assets from "assets";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as S from "./Header.styles";

interface IProps {}

const Header: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const { pathname = "/" } = useLocation();

  // Render
  const locales = [ELocale.NL, ELocale.FR];
  if (CONFIG.ENVIRONMENT === EEnvironment.Dev) {
    locales.push(ELocale.EN);
    locales.push(ELocale.INTERNAL);
  }

  // TODO: Dirty
  const pathsWithoutBackButton = ["/", "/request/success"];
  const showBackButton = !pathsWithoutBackButton.includes(pathname);

  return (
    <>
      <S.Wrap>
        <S.InnerWrap>
          <S.IconWrap>
            {showBackButton && (
              <S.BackButton
                onClick={() => {
                  navigate(-1);
                }}
              >
                <S.BackIcon src={assets.ICO_BACK} />
              </S.BackButton>
            )}
          </S.IconWrap>

          <S.Logo src={assets.ICO_LOGO} alt="Rentio Insurance" />

          <S.IconWrap />
          {/* <S.LogoBaloise src={icoLogoBaloise} alt="Baloise Insurance" /> */}
          {/* <S.LanguageSwitcherWrap>
            {locales.map((locale, index) => (
              <React.Fragment key={locale}>
                {index > 0 && <span>|</span>}
                <S.LanguageButton
                  onClick={handleClickLocale(locale)}
                  isActive={locale === getLocale()}
                >
                  {getLocalizedText(
                    `system.locale.short_key.${locale}`.toLowerCase(),
                  )}
                </S.LanguageButton>
              </React.Fragment>
            ))}
          </S.LanguageSwitcherWrap> */}
        </S.InnerWrap>
      </S.Wrap>
      <S.Border />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Header;
