import { ThemeProvider } from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
import { ThemeProvider as ThemeProviderNative } from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import rentioInsuranceTheme, {
  rentioInsuranceThemeNative,
} from "@rentiohq/shared-frontend/dist/components/theme/theme.rentio_insurance";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import {
  EEnvironment,
  EProduct,
} from "@rentiohq/shared-frontend/dist/config/app.config.types";
import logger from "@rentiohq/shared-frontend/dist/logger";
import consoleLogger from "@rentiohq/shared-frontend/dist/logger/loggers/logger.console";
import sentryLogger from "@rentiohq/shared-frontend/dist/logger/loggers/logger.sentry";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { setMappedLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { setStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import * as Sentry from "@sentry/browser";
import AppRouter from "AppRouter";
import AlertTemplate from "components/Alert";
import ConfirmProvider from "providers/ConfirmProvider";
import DatePickerProvider from "providers/DatePickerProvider";
import I18nProvider from "providers/I18nProvider";
import UtilAlertProvider from "providers/UtilAlertProvider";
import React from "react";
import { Provider as AlertProvider } from "react-alert";
import { Provider as ReduxProvider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import store from "redux/store";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: CONFIG.SENTRY[EProduct.Insurance],
    environment: CONFIG.ENVIRONMENT,
    release: CONFIG.SENTRY_RELEASE,
  });
  logger.registerLogger(sentryLogger);
}

if (process.env.NODE_ENV === "development") {
  logger.registerLogger(consoleLogger);
}

let mappedLocale = ELocale.NL;
if (navigator.language.toLowerCase().includes("fr")) {
  mappedLocale = ELocale.FR;
} else if (
  navigator.language.toLowerCase().includes("en") &&
  CONFIG.ENVIRONMENT === EEnvironment.Dev
) {
  mappedLocale = ELocale.EN;
}
setMappedLocale(mappedLocale);
setStore(store);

class App extends React.Component {
  // Render
  public render() {
    return (
      <ReduxProvider store={store}>
        <ThemeProvider theme={rentioInsuranceTheme}>
          <ThemeProviderNative theme={rentioInsuranceThemeNative}>
            <PersistGate
              // @ts-ignore
              persistor={persistStore(store)}
              // loading={<Page />}
            >
              <AlertProvider {...CONFIG.ALERT} template={AlertTemplate}>
                <UtilAlertProvider>
                  <ConfirmProvider>
                    <DatePickerProvider>
                      <I18nProvider>
                        <AppRouter />
                      </I18nProvider>
                    </DatePickerProvider>
                  </ConfirmProvider>
                </UtilAlertProvider>
              </AlertProvider>
            </PersistGate>
          </ThemeProviderNative>
        </ThemeProvider>
      </ReduxProvider>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default App;
